var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"position-relative sort-row-wrapper",attrs:{"align-v":"center"}},[_c('div',{staticClass:"count pr-3"},[(_vm.$store.getters.listings)?_c('h6',{staticClass:"m-0"},[(_vm.count)?_c('b',{staticStyle:{"white-space":"nowrap"}},[_vm._v(_vm._s(_vm.count)+" "+_vm._s(_vm.$t("dashboard.label.vehicles")))]):_vm._e()]):(_vm.loading)?_c('h6',[_vm._v(" "+_vm._s(_vm.$t('search.label.loading')))]):_c('h6',[_vm._v(_vm._s(_vm.$t("search.label.noResults")))])]),(
      this.getUserFilters.Model.length > 0 ||
      this.getUserFilters.Make.length > 0 ||
      this.getUserFilters.Year.length > 0
    )?_c('div',{staticClass:"px-0 has-order"},[_c('swiper',{ref:"swiper",staticClass:"swiper",attrs:{"options":_vm.swiperOptions}},[_vm._l((_vm.getFiltersFlatten().Make),function(make){return _c('swiper-slide',{key:make.TagValueId},[_c('v-chip',{attrs:{"close":""},on:{"click:close":function($event){return _vm.removeItemUserFilters({ type: 'Make', item: make })}}},[_vm._v(" "+_vm._s(make.Value)+" ")])],1)}),_vm._l((_vm.getFiltersFlatten().Model),function(model){return _c('swiper-slide',{key:model.TagValueId},[_c('v-chip',{attrs:{"close":""},on:{"click:close":function($event){return _vm.removeItemUserFilters({ type: 'Model', item: model })}}},[_vm._v(" "+_vm._s(model.ParentTagValueLabel + " " + model.Value)+" ")])],1)}),(_vm.getFiltersFlatten().Year.length)?_c('swiper-slide',[_c('v-chip',{attrs:{"close":""},on:{"click:close":function($event){return _vm.removeItemUserFilters({ type: 'Year', item: [] })}}},[_vm._v(" "+_vm._s(_vm.getFiltersFlatten().Year[0])+" - "+_vm._s(_vm.getFiltersFlatten().Year[1])+" ")])],1):_vm._e()],2)],1):_vm._e(),(
      this.getUserFilters.Model.length > 0 ||
      this.getUserFilters.Make.length > 0 ||
      this.getUserFilters.Year.length > 0
    )?_c('div',{staticClass:"text-right ml-auto pl-3 clear-filter"},[_c('button',{on:{"click":_vm.clearUserFilters}},[_vm._v(_vm._s(_vm.$t('search.button.clearSearch')))])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }